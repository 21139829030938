import { Entity } from "./entity.model";
import { Occupation } from "./occupation.model";

export interface SingleResource {
    id: number;
    name: string;
    tag: string;
    slug: string;
    description: string;
    image: string;
    indications?: Indication[];
    blocks: SingleBlock[];
    phone?: string;
    email?: string;
    mails?: Mail[];
    address?: string;
    latitude?: number;
    longitude?: number;
    tags?: Tag[]
    companies?: Entity[];
    organizations?: Entity[];
    occupations?: Occupation[];
    type?: string;
    link?: string;
    meta_title?: string,
    meta_description?: string,
}

export interface SingleBlock {
    order: number;
    type: SingleBlockType;
}

export enum SingleBlockType {
  Text = 'text',
  Video = 'video',
  Gallery = 'gallery',
  KeyFigure = 'key_figure'
}

export interface SingleTextBlock extends SingleBlock {
    cta_label?: string;
    cta_link?: string;
    text: string;
    title: string;
}

export interface SingleVideoBlock extends SingleBlock {
  link: string;
}

export interface SingleGalleryBlock extends SingleBlock {
  images: string[];
}

export interface Tag {
  id: number;
  name: string;
  slug: string;
  order: number;
  type: string | null
}

export interface Mail {
  firstname: string;
  lastname: string;
  role: string;
  mail: string;
}

export interface Indication {
  id: number | string;
  category: string;
  title: string;
  icon: string
}